
import { defineComponent, reactive, ref, PropType, watchEffect, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useNotify } from '@/store'

import { isRequired } from '@/utils/validation'
import { isOfType } from '@/utils/types/isOfType'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useCreateOrgUnit, useUpdateOrgUnit } from '@/api/orgUnit'
import { useGetCostCentersBasic } from '@/api/costCenter'

import { CorporateUnitId, CorporateUnitTreeStructure } from '@/api/types/corporateUnit'
import { BasicEntity, UNIT_TYPE } from '@/api/types/misc'
import { OrgUnit, NewOrgUnit, OrgUnitId, OrgUnitTreeStructure } from '@/api/types/orgUnit'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { HierarchyItem } from '@/views/baseData/views/types'

export default defineComponent({
  name: 'AddEditOrgUnitDialog',
  components: {
    CommonAddEditDialog,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    corporateUnitId: {
      type: Number as PropType<CorporateUnitId>,
      default: null,
    },
    orgUnitParentId: {
      type: Number as PropType<OrgUnitId>,
      default: null,
    },
    orgUnitToEdit: {
      type: Object as PropType<OrgUnit>,
      default: null,
    },
    treeViewItems: {
      type: Array as PropType<(CorporateUnitTreeStructure | OrgUnitTreeStructure)[]>,
      default: null,
    },
  },
  setup: (props, { emit, root }) => {
    const isEditMode = computed(() => Boolean(props.orgUnitToEdit))

    const { addNotification } = useNotify()

    const {
      exec: getCostCentersBasic,
      data: costCenters,
      isLoading: isLoadingCostCentersBasic,
    } = useGetCostCentersBasic()
    getCostCentersBasic()

    const form = ref<NewOrgUnit | OrgUnit>(isEditMode.value ? cloneDeep(props.orgUnitToEdit) : ({} as NewOrgUnit))

    const FORM_FIELDS: FormField[] = [
      {
        value: 'name',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('baseData.corporateStructure.orgUnitDialog.form.name') as string),
        ],
      },
      {
        value: 'abbreviation',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('baseData.corporateStructure.orgUnitDialog.form.abbreviation') as string),
        ],
      },
      {
        value: 'type',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'costCenter',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => costCenters.value ?? []),
        isLoading: computed(() => isLoadingCostCentersBasic.value),
        isRequired: false,
        rules: [],
      },
    ]

    props.orgUnitToEdit && isEditMode.value && (form.value = props.orgUnitToEdit)

    watchEffect(() => props.corporateUnitId && (form.value.corporateUnit = props.corporateUnitId))
    watchEffect(() => props.orgUnitParentId && (form.value.parent = props.orgUnitParentId))

    const { createOrgUnit, isLoading: isLoadingCreateOrgUnit } = useCreateOrgUnit()

    async function onAdd(): Promise<void> {
      if (!isOfType<OrgUnit>(form.value, 'id') && !isEditMode.value) {
        try {
          const resOrgUnit = await createOrgUnit(form.value)

          addNotification({
            text: root.$t('misc.added') as string,
            type: 'success',
            timeout: 3000,
          })

          close(resOrgUnit)
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateOrgUnit, isLoading: isLoadingUpdateOrgUnit } = useUpdateOrgUnit()

    async function onEdit(): Promise<void> {
      if (isOfType<OrgUnit>(form.value, 'id')) {
        try {
          let updatedForm = mapBasicEntityToIdWithForm<OrgUnit>(form.value)

          const resOrgUnit = await updateOrgUnit(updatedForm.id, updatedForm)

          addNotification({
            text: root.$t('misc.edited') as string,
            type: 'success',
            timeout: 3000,
          })
          ;(resOrgUnit as OrgUnitTreeStructure).unitType = UNIT_TYPE.ORG_UNIT

          close(resOrgUnit)
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditOrgUnit = computed(() => isLoadingCreateOrgUnit.value || isLoadingUpdateOrgUnit.value)

    const corporateUnits = computed(() => {
      const corporateUnits: HierarchyItem[] = []
      // For all top level items (corp.units)
      props.treeViewItems.forEach((item) => {
        if (item.unitType === UNIT_TYPE.CORP_UNIT) {
          corporateUnits.push({ text: item.name, value: item.id, level: 0 })
        }
      })
      return corporateUnits
    })

    const possibleParents = computed(() => {
      const possibleParents: HierarchyItem[] = [{ text: '/', value: -1, level: 0 }]
      props.treeViewItems.forEach((item) => {
        // Only the corp. unit this item belongs to
        if (item.id === (form.value.corporateUnit as BasicEntity)?.id) {
          addItemPlusChildrenToPossibleParents({
            item: item as CorporateUnitTreeStructure,
            origItemId: props.orgUnitToEdit.id,
            level: 0,
            possibleParents,
          })
        }
      })

      return possibleParents
    })

    function addItemPlusChildrenToPossibleParents(itemWithChildren: {
      item: OrgUnitTreeStructure | CorporateUnitTreeStructure
      origItemId: number
      level: number
      possibleParents: HierarchyItem[]
    }) {
      const { item, origItemId, level, possibleParents } = itemWithChildren

      if (item.unitType === UNIT_TYPE.ORG_UNIT) {
        // Don't add item itself or its descendants
        if (item.id === origItemId) {
          return
        }
        possibleParents.push({ text: item.name, value: item.id, level: level })
      }

      item.organizationalUnits.forEach((child) => {
        addItemPlusChildrenToPossibleParents({
          item: child as OrgUnitTreeStructure,
          origItemId,
          level: level + 1,
          possibleParents,
        })
      })
    }

    function onCorporateUnitChange(newUnitId: number) {
      form.value.corporateUnit = {
        id: newUnitId,
        name: corporateUnits.value.find((unit) => unit.value === newUnitId)?.text || '',
      }

      form.value.parent = null
    }

    function onParentChange(newParentId: number) {
      if (newParentId === -1) {
        form.value.parent = null
      } else {
        form.value.parent = {
          id: newParentId,
          name: possibleParents.value.find((parent) => parent.value === newParentId)?.text || '',
        }
      }
    }

    function close(orgUnit: OrgUnit): void {
      emit('added-edited', orgUnit)
      emit('input', false)
      emit('inputOrgUnitParentId', null)

      form.value = {} as NewOrgUnit
    }

    function onAbort(): void {
      emit('input', false)
      emit('inputOrgUnitParentId', null)

      form.value = {} as NewOrgUnit
    }

    return reactive({
      constants: {
        DATA_TYPE,
        FORM_FIELDS_ENUM,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        corporateUnits,
        possibleParents,

        isLoadingAddEditOrgUnit,
      },
      listeners: {
        onAdd,

        onEdit,

        onAbort,

        onCorporateUnitChange,
        onParentChange,
      },
    })
  },
})
