var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonAddEditDialog', {
    attrs: {
      "value": _vm.value,
      "is-edit-mode": _vm.state.isEditMode,
      "is-loading": _vm.state.isLoadingAddEditOrgUnit
    },
    on: {
      "add": function add($event) {
        _vm.state.isEditMode ? _vm.listeners.onEdit() : _vm.listeners.onAdd();
      },
      "close": function close($event) {
        return _vm.listeners.onAbort();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("baseData.corporateStructure.orgUnitDialog.".concat(_vm.state.isEditMode ? 'edit' : 'add', ".title"))) + " ")];
      },
      proxy: true
    }, {
      key: "fields",
      fn: function fn() {
        return [_vm._l(_vm.constants.FORM_FIELDS, function (entry) {
          var _entry$isLoading$valu, _entry$isLoading;

          return _c('div', {
            key: entry.value
          }, [entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXT ? [entry.dataTyp === _vm.constants.DATA_TYPE.TEXT ? _c('v-text-field', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t("baseData.corporateStructure.orgUnitDialog.form.".concat(entry.value)),
              "rules": [].concat(entry.rules)
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e()] : entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.DROPDOWN ? _c('CommonAutocomplete', {
            class: entry.isRequired && 'required',
            attrs: {
              "items": entry.items.value,
              "item-text": "name",
              "item-value": "id",
              "loading": (_entry$isLoading$valu = (_entry$isLoading = entry.isLoading) === null || _entry$isLoading === void 0 ? void 0 : _entry$isLoading.value) !== null && _entry$isLoading$valu !== void 0 ? _entry$isLoading$valu : false,
              "rules": [].concat(entry.rules),
              "label": _vm.$t("baseData.corporateStructure.orgUnitDialog.form.".concat(entry.value))
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e()], 2);
        }), _vm.state.isEditMode ? _c('div', [_c('v-select', {
          staticClass: "parent-select",
          attrs: {
            "value": _vm.state.form.corporateUnit.id || _vm.state.form.corporateUnit,
            "label": _vm.$t("baseData.corporateStructure.orgUnitDialog.form.corporateUnit"),
            "items": _vm.state.corporateUnits,
            "disabled": _vm.state.corporateUnits.length < 2
          },
          on: {
            "change": _vm.listeners.onCorporateUnitChange
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_c('span', [_vm._v(_vm._s(item.text))])];
            }
          }], null, false, 615712039)
        })], 1) : _vm._e(), _vm.state.isEditMode ? _c('div', [_c('v-select', {
          staticClass: "parent-select",
          attrs: {
            "value": _vm.state.form.parent ? _vm.state.form.parent.id : -1,
            "label": _vm.$t("baseData.corporateStructure.orgUnitDialog.form.parent"),
            "items": _vm.state.possibleParents,
            "disabled": _vm.state.possibleParents.length < 2
          },
          on: {
            "change": _vm.listeners.onParentChange
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('span', {
                style: {
                  'margin-left': item.level * 16 + 'px',
                  'grey--text': item.text === '/'
                }
              }, [_vm._v(" " + _vm._s(item.text) + " ")])];
            }
          }], null, false, 4001615846)
        })], 1) : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }